<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(rgba(24,62,49,0.2),black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="col-lg-8 col-sm-12">
        <h1 class="tulum-party-title">DJ's </h1>
        <p style="margin-top: -35px; color: #00f2c3 !important;">Reference book</p>
        <hr class="gradient" style="height: 1px">

        <br>
        <div id="artists" class="row">

          <div v-for="(artist, idx) in artists"
               :key="idx" class="col-sm-12 "
          >
            <div v-if="artist.metadata" class="text-center card">

              <router-link :to="{name: 'Artist', params: {slug: artist.slug} }" style="font-size: 1rem;margin: 0.7rem"

              >
                <img
                  v-if="artist.metadata.main_image"
                  height="80px"
                  width="80px"
                  style="border-radius: 80px"
                  v-lazy="artist.metadata.main_image.imgix_url+'?w=80&h=80'" :alt="artist.title">

                <img
                  v-if="!artist.metadata.main_image"
                  height="80px"
                  width="80px"
                  style="border-radius: 80px"
                  v-lazy="djImg+'?w=80&h=80'" :alt="artist.title">
                <br>
                <br>
                <h3
                  >{{ artist.title }}</h3>
              </router-link>


            </div>


          </div>
        </div>

        <hr>
        <p>Showing {{ (val > 1 ? pageSize + 1 : 1) }} - {{ artists ? artists.length + (val > 1 ? pageSize : 0) : 0 }} of
          {{ total }} DJ's | <a style="display: inline-block" class="btn btn-link" @click="showAll" v-if="!showingAll">Show
            all ({{ total }})</a></p>

        <BasePagination type="primary" :per-page="pageSize" :total="total" :value="val" v-if="!showingAll"
                        :nextPage="nextPage" :prevPage="prevPage" :changePage="changePage"></BasePagination>


      </section>
      <aside class="card col-4 col-xl-4 d-none d-xl-inline">
        <SmartMenu :links="links" :logo="this.djImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                   :primary-color="secondaryColor">

          <div class="card">
            <p>Want to talk with some one? <br> Drop us a line. We are here to assist.</p>
          </div>


        </SmartMenu>
      </aside>

    </article>
  </div>

</template>

<script>
import BasePagination from '../../components/BasePagination.vue';
import ImgCard from "@/components/Cards/ImgCard";
import MiniArtistCard from "@/components/Cards/MiniArtistCard";
import SmartMenu from "@/components/SmartMenu/SmartMenu";

export default {
  name: 'Artists',
  metaInfo() {
    return {
      title: `Tulum - DJ Lineup`,
      meta: [
        {
          name: 'description',
          content: `DJ's who will be playing or played in Tulum`,
        },
        {
          name: 'keywords',
          content: `Tulum DJs Lineup Artists Electronic Music`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
      table: [],
      color: 'blue',
      djImg: "https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png",
      showingAll: false,
      showPalms: false,
      pageSize: 20,
      val: undefined,
      page: 0,
      secondaryColor: '#64665a',
      showModal: false,
    };
  },
  components: {
    MiniArtistCard,
    ImgCard,
    SmartMenu,
    BasePagination
  },
  created: function () {
    // this.$store.dispatch('getArtists');
    this.$store.dispatch('getArtists', {start: 0, limit: this.pageSize});
    this.$store.dispatch('getLinks', {slug: 'djs-'});

  },
  methods: {
    artistImg: function (artist) {
      return artist.metadata?.main_image?.imgix_url || this.djImg;
    },
    changePage: function (page) {
      this.page = page;
      this.val = this.page;
      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: this.pageSize});

    },
    nextPage: function () {
      this.page++;
      this.val = this.page;
      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: this.pageSize});
    },
    showAll: function () {
      this.$store.dispatch('getArtists', {start: 0, limit: this.total});
      this.showingAll = true;

    },
    prevPage: function () {
      this.page--;
      this.val = this.page;

      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: 20});
    }
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    artists: {
      get() {
        return this.$store.state.artists;
      },
    },
  },
};
</script>

