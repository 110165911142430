<template>
  <div class="card card-collapse text-left " style="margin: 10px" >

    <router-link :to="{path: event.metadata.link}" @click.native="logClick" class="card-animation-on-hover">
      <img
        v-if="this.image"
        v-lazy=" this.image + '?q=&w=50&h=50&fit=clamp' "
        style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 40px;border: 1px solid #b69603"
        v-bind:alt="this.event.title"
        height="50px"
        width="50px"
      />
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; line-height: 0.7rem">
        <span style="font-weight: 700 !important;"
        >{{ event.title.split("@")[0] }}</span>
        <p v-if="event.metadata.start_time" >

          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }},
          {{ getYear(event.metadata.date + 'T' + event.metadata.start_time) }}
          ●

          {{
            event.metadata.start_time
          }} Hrs

        </p>

        <p v-if="!event.metadata.start_time">
          {{ getDay(event.metadata.date + 'T' + '00:00') }},
          {{ getDay3(event.metadata.date + 'T' + '00:00') }}
          {{ getDay2(event.metadata.date + 'T' + '00:00') }}
        </p>
        <small style="margin-top: -50px;color: silver;text-transform: uppercase"
        >{{ this.event.title.split('@')[1] }}
        </small>


      </div>

    </router-link>




  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'MiniArtistCard',
  components: {},
  props: ['event','image'],
  data: () => {
    return {
      expandTab: false
    };
  },
  // computed: {
  //   artistImage: {
  //     get() {
  //       return (
  //         this.event.metadata ? this.event.metadata.main_image.imgix_url : false
  //       )
  //     },
  //   },
  // },
  methods: {
    expand: function expand() {
      this.expandTab = !this.expandTab;
    },
    logClick: function logClick() {
      this.$rollbar.info("Artist MiniCard", this.event.title)
    },
    getDay: function getYear(date) {
      return moment(date, true).format('ddd')
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD')
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM')
    },
    getYear: function getMonth(date) {
      return moment(date, true).format('YYYY')
    },

  }
};
</script>

